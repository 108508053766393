import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import RuledBreak from "../../../components/RuledBreak"

const Videos = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={400}
      mobile={300}
    >
      <SEO title="Videos" keywords={["Education", "Videos"]} />
      {/* ----------------------------------------------------------------------- */}
      <div className="row">
        <div className="col-md-12">
          <RuledBreak>Shop Videos</RuledBreak>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-12">
          <h3>will show videos here</h3>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
    </Layout>
  )
}

export const query = graphql`
  query videosQuery {
    header: allFile(filter: { name: { eq: "home_header_1200_791" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Videos
